(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/win-div/assets/javascripts/utils/win-div-utils.js') >= 0) return;  svs.modules.push('/components/tipsen/win-div/assets/javascripts/utils/win-div-utils.js');
"use strict";

const _excluded = ["count"],
  _excluded2 = ["isPlayersBestWinDiv", "playerWins"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (-1 !== e.indexOf(n)) continue; t[n] = r[n]; } return t; }

const {
  getLogger
} = svs.core.log;
const logger = getLogger('tipsen:win-div:utils');
const getPlayersBestWinDiv = function () {
  let playerWinDivs = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  if (Object.keys(playerWinDivs).length === 0) {
    return null;
  }
  const _Object$values$filter = Object.values(playerWinDivs).filter(betRow => betRow.count > 0).sort((a, b) => a.winDiv - b.winDiv).shift(),
    {
      count
    } = _Object$values$filter,
    playersBestWinDiv = _objectWithoutProperties(_Object$values$filter, _excluded);
  return _objectSpread(_objectSpread({}, playersBestWinDiv), {}, {
    isPlayersBestWinDiv: true,
    playerWins: count
  });
};
const getCorrectAmountHeaderText = _ref => {
  let {
    isSportkryss,
    hasPlayerData,
    isGroup,
    isExtraSmall
  } = _ref;
  if (isSportkryss) {
    return 'Vinnare';
  }
  const headerText = isExtraSmall ? 'Rader' : 'Rätta rader';
  if (!hasPlayerData) {
    return headerText;
  }
  return headerText.concat(" ".concat(isGroup ? '(lagets)' : '(dina)'));
};
const getPlayersCorrectAmountText = (playerWins, isExpanded, isGroup, hasPlayerWinDivs) => {
  if (!playerWins || !hasPlayerWinDivs) {
    return '';
  }
  const text = "".concat(isGroup ? 'Laget' : 'Du', " har");
  return "".concat(isExpanded ? text : '', " ").concat(playerWins, " st");
};
const getBestRowText = _ref2 => {
  let {
    isBestWinDiv,
    isPlayersBestWinDiv,
    isGroup,
    isOnlyForCollapsedView
  } = _ref2;
  if (!isBestWinDiv && !isPlayersBestWinDiv) {
    return '';
  }
  if (isPlayersBestWinDiv && !isBestWinDiv && !isOnlyForCollapsedView) {
    return "".concat(isGroup ? 'Lagets' : 'Din', " b\xE4sta rad");
  }
  return 'Bästa rad';
};
const getDuplicatedBestWinDivForCollapsedView = (combinedWinDivs, bestWinDiv) => {
  const _combinedWinDivs$best = combinedWinDivs[bestWinDiv],
    {
      isPlayersBestWinDiv,
      playerWins
    } = _combinedWinDivs$best,
    bestRowDuplicate = _objectWithoutProperties(_combinedWinDivs$best, _excluded2);
  return _objectSpread(_objectSpread({}, bestRowDuplicate), {}, {
    isDuplicated: true
  });
};
const getCombinedWinDivAlteredForMobile = (combinedWinDivs, playerWinDivs) => {
  const bestWinDiv = combinedWinDivs.findIndex(row => row.winners > 0);
  const playersBestWinDivIndex = combinedWinDivs.findIndex(row => row.playerWins > 0);
  const combinedWinDivsAltered = [...combinedWinDivs];
  if (Object.keys(playerWinDivs).length > 0 && playersBestWinDivIndex < 0) {
    logger.debug('Adding players missing best windiv to end of combined windivs');
    const playersBestWinDiv = getPlayersBestWinDiv(playerWinDivs);
    combinedWinDivsAltered.push(_objectSpread(_objectSpread({}, playersBestWinDiv), {}, {
      isPlayersBestWinDiv: true
    }));
  }
  if (bestWinDiv === playersBestWinDivIndex) {
    logger.debug('Adding duplicated best row to windivs for showing in collapsed mobile view');
    const bestWinDivDuplicate = getDuplicatedBestWinDivForCollapsedView(combinedWinDivs, bestWinDiv);
    combinedWinDivsAltered.splice(bestWinDiv, 0, bestWinDivDuplicate);
  }
  return combinedWinDivsAltered;
};
setGlobal('svs.components.tipsen.winDiv.utils', {
  getPlayersBestWinDiv,
  getCorrectAmountHeaderText,
  getPlayersCorrectAmountText,
  getBestRowText,
  getCombinedWinDivAlteredForMobile
});

 })(window);